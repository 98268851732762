@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


* { @apply m-0 p-0; box-sizing: border-box;font-family: 'Inter', sans-serif !important; }
html { text-rendering: optimizeLegibility;overflow-x: hidden; }
body { font-family: 'Inter', sans-serif; @apply bg-body-bg; }

button, a { @apply transition-all duration-300; }

.table { @apply w-full border-collapse text-left }
.table th { @apply bg-thead-bg !text-primary font-bold uppercase }
.table th, .table td { @apply px-5 py-3 text-dark }


/* global antd table styles */
.ant-table-wrapper .ant-table-thead .ant-table-cell { @apply !bg-primary !text-white font-bold uppercase !py-3 }
.ant-table-wrapper .ant-table-container table > thead > tr > th::before { content: none !important; }
/*  } 
.ant-table-wrapper .ant-table-container table > thead > tr:first-child > :last-child { border-start-end-radius: 0 !important; } */
.ant-table-wrapper .ant-table-tbody > tr > td { color: #5B5D66;border-bottom: 0; }
.ant-table-wrapper .ant-table-tbody > tr:nth-child(2n+1) > td { @apply !bg-[#E8F0FFA3] }
.ant-table-wrapper .ant-table-tbody > tr:last-child > td:first-child { @apply rounded-bl-lg }

.ant-tabs-tab-active  { @apply bg-black relative z-20}

/* global button styles */
.btn-primary {
    @apply text-base h-12 font-semibold bg-primary text-white px-5 py-3 rounded-lg hover:bg-primary-light focus:bg-primary-light
}
.btn-primary.secondary {
    @apply bg-secondary hover:bg-slate-300 hover:text-primary focus:bg-primary-light
}
.btn-primary.red {
    @apply bg-[#00AFEF] hover:bg-slate-300 hover:text-primary focus:bg-primary-light
}

.input {
    @apply w-full h-12 px-4 py-2 rounded-lg bg-white border border-[#d9d9d9] focus:outline-none focus:border-primary
}